import { template as template_a5cb682649fa4dc5a0bc2b5d814ed0ba } from "@ember/template-compiler";
const FKLabel = template_a5cb682649fa4dc5a0bc2b5d814ed0ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
