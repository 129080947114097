import { template as template_08c91e480ad149ed97727e96c3c9955f } from "@ember/template-compiler";
const FKText = template_08c91e480ad149ed97727e96c3c9955f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
